
























































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import firebase from '@/plugins/firebase'
import { VehicleSecond } from 'functions/src/types/vehicle/second'
// import { AccidentPoints, DriveScorePoints, AccPointsCalc } from '@/../functions/src/types/vehicle/drive-score-points'
import SecondsMap from '@/components/common/SecondsMap.vue'
import SecondsTable from '@/components/common/SecondsTable.vue'
import SecondsScore from '@/components/common/SecondsScore.vue'
import { Trip } from '@/models/trip'
import eventCollection, { Event } from '@/models/event'

type AccidentPoints = {
  [key: string]: string | number
} & {
  'type': string;
  'time': string;
  'latitude': number;
  'longitude': number;
  'oldSpeed': number;
  'speed': number;
  'oldAzimuth': number;
  'azimuth': number;
  'maxSpeed': number;
}

type DriveScorePoints = {
  [key: string]: AccidentPoints[]
} & {
  overSpeed: AccidentPoints[];
  longOverSpeed: AccidentPoints[];
  fastAcceleration: AccidentPoints[];
  fastDeceleration: AccidentPoints[];
  fastRouteChange: AccidentPoints[];
  fastTurn: AccidentPoints[];
}

@Component<SecondsTab>({
  components: { SecondsMap, SecondsTable, SecondsScore },
  created () {
    this.fetch()
  }
})
export default class SecondsTab extends Vue {
  @Prop({ type: Array, required: true, default: () => [] }) seconds!: VehicleSecond[]
  @Prop({ type: Object, required: true }) doc!: firebase.firestore.QueryDocumentSnapshot<Trip>
  @Prop({ type: Boolean, required: true }) active!: boolean
  events: Event[] = []
  driveScorePoints: DriveScorePoints = {
    overSpeed: [],
    longOverSpeed: [],
    fastAcceleration: [],
    fastDeceleration: [],
    fastRouteChange: [],
    fastTurn: []
  }

  loading = false

  @Watch('active')
  onChangeActive (active: boolean): void {
    if (!active) return
    this.fetch()
  }

  get item (): Trip {
    return this.doc.data()
  }

  get accidentMarkPoints (): AccidentPoints[] {
    const points: AccidentPoints[] = []
    for (const [key, value] of Object.entries(this.driveScorePoints)) {
      value.map((point: AccidentPoints) => { points.push(point) })
    }
    return points
  }

  async fetch (): Promise<void> {
    console.log('fetch')
    this.dataClear()
    try {
      this.loading = true
      const ref = eventCollection
        .where('device', '==', this.item.device)
        .where('company', '==', this.item.company)
        .where('group', '==', this.item.group)
        .where('lastSecond.time', '>=', this.item.firstSecond.time)
        .where('lastSecond.time', '<=', this.item.lastSecond.time)
        .orderBy('lastSecond.time', 'asc')
      const sn = await ref.get()
      if (sn.empty) return
      for (const doc of sn.docs) this.events.push(doc.data())
      for (const event of this.events) {
        this.driveScorePoints[event.type].push({
          latitude: event.lastSecond.latitude,
          longitude: event.lastSecond.longitude,
          azimuth: event.lastSecond.azimuth,
          oldAzimuth: event.firstSecond.azimuth,
          speed: event.lastSecond.speed,
          oldSpeed: event.firstSecond.speed,
          time: event.lastSecond.time,
          type: event.type,
          maxSpeed: event.lastSecond.MAX_SPD || 110
        })
      }
    } finally {
      this.loading = false
    }
  }

  dataClear (): void {
    this.events = []
    this.driveScorePoints = {
      overSpeed: [],
      longOverSpeed: [],
      fastAcceleration: [],
      fastDeceleration: [],
      fastRouteChange: [],
      fastTurn: []
    }
  }

  pointsValue = ''
}
