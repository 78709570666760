








































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { DriveScorePoints } from '@/../functions/src/types/vehicle/drive-score-points'
import moment from '@/plugins/moment'

@Component({ components: { } })
export default class SecondsScore extends Vue {
  @Prop({ type: Object, required: true, default: () => [] }) driveScorePoints!: DriveScorePoints
  activeValue = ''
  accidents = [
    { value: 'overSpeed', text: '과속' },
    { value: 'longOverSpeed', text: '장기과속' },
    { value: 'fastAcceleration', text: '급가속' },
    { value: 'fastDeceleration', text: '급감속' },
    { value: 'fastRouteChange', text: '급진로변경' },
    { value: 'fastTurn', text: '급회전' }
  ]

  headers = [
    { value: 'time', text: '시간' },
    { value: 'oldSpeed', text: '이전속도' },
    { value: 'speed', text: '속도' },
    { value: 'oldAzimuth', text: '이전방위각' },
    { value: 'azimuth', text: '방위각' }
  ]

  getColor (type: string): string {
    let iconColor = 'default'
    switch (type) {
      case 'overSpeed':
        iconColor = 'red'
        break
      case 'longOverSpeed':
        iconColor = 'pink'
        break
      case 'fastAcceleration':
        iconColor = 'orange'
        break
      case 'fastDeceleration':
        iconColor = 'cyan'
        break
      case 'fastRouteChange':
        iconColor = 'lime'
        break
      case 'fastTurn':
        iconColor = 'blue-grey'
        break
      default:
        break
    }
    return iconColor
  }

  getIcon (type: string): string {
    let iconName = 'mdi-check'
    switch (type) {
      case 'overSpeed':
        iconName = 'mdi-upload'
        break
      case 'longOverSpeed':
        iconName = 'mdi-upload-multiple'
        break
      case 'fastAcceleration':
        iconName = 'mdi-speedometer'
        break
      case 'fastDeceleration':
        iconName = 'mdi-speedometer-slow'
        break
      case 'fastRouteChange':
        iconName = 'mdi-swap-horizontal'
        break
      case 'fastTurn':
        iconName = 'mdi-axis-z-rotate-counterclockwise'
        break
      default:
        break
    }
    return iconName
    // return this.type === 'start' ? 'mdi-play' : 'mdi-stop'
  }

  time (date: string): string {
    return moment(date).format('a h:mm:ss')
  }

  showAzimuth (type: string): boolean {
    let show = false
    switch (type) {
      case 'overSpeed':
      case 'longOverSpeed':
      case 'fastAcceleration':
      case 'fastDeceleration':
        break
      case 'fastRouteChange':
      case 'fastTurn':
        show = true
        break
      default:
        break
    }
    return show
  }

  showSpeedLimit (type: string): boolean {
    let show = false
    switch (type) {
      case 'overSpeed':
      case 'longOverSpeed':
        show = true
        break
      case 'fastAcceleration':
      case 'fastDeceleration':
      case 'fastRouteChange':
      case 'fastTurn':
        break
      default:
        break
    }
    return show
  }

  // valueClick (value: string): void {
  //   if (value !== this.activeValue) this.$emit('mark-points', value)
  //   this.activeValue = value
  // }
}
