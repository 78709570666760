











import { Component, Vue, Prop } from 'vue-property-decorator'
import moment from '@/plugins/moment'

@Component
export default class TimeDuration extends Vue {
  @Prop({ type: Number, required: true, default: () => 0 }) seconds!: number

  get time (): string {
    const format = this.seconds >= 86400 ? 'D일 H:mm:ss' : 'H:mm:ss'
    return moment(0).startOf('days').seconds(this.seconds).format(format)
  }

  get humanize (): string {
    return moment.duration(this.seconds, 'seconds').humanize()
  }
}
