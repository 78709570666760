import firebase, { db } from '../plugins/firebase'
import VehicleInfo from '../../functions/src/types/vehicle/info'
import { VehicleSecond } from '../../functions/src/types/vehicle/second'

export class Event {
  constructor (
    readonly device: firebase.firestore.DocumentReference,
    readonly info: VehicleInfo,
    readonly firstSecond: VehicleSecond,
    readonly lastSecond: VehicleSecond,
    readonly company: firebase.firestore.DocumentReference | null,
    readonly group: firebase.firestore.DocumentReference | null,
    readonly type: string,
    readonly createdAt?: Date,
    readonly updatedAt?: Date
  ) {

  }
}

export const converter: firebase.firestore.FirestoreDataConverter<Event> = {
  toFirestore (model: Partial<Event>, setOptions?: firebase.firestore.SetOptions) {
    if (setOptions?.merge) {
      return Object.assign(model,
        {
          updatedAt: firebase.firestore.FieldValue.serverTimestamp()
        }
      )
    }

    return {
      device: model.device,
      info: model.info,
      firstSecond: model.firstSecond,
      lastSecond: model.lastSecond,
      company: model.company,
      group: model.group,
      type: model.type || '',
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp()
    }
  },
  fromFirestore (snapshot: firebase.firestore.QueryDocumentSnapshot) {
    const data = snapshot.data()

    return new Event(
      (data.device as firebase.firestore.DocumentReference),
      data.info,
      data.firstSecond,
      data.lastSecond,
      data.company,
      data.group,
      data.type || '',
      (data.createdAt as firebase.firestore.Timestamp)?.toDate(),
      (data.updatedAt as firebase.firestore.Timestamp)?.toDate()
    )
  }
}
export default db.collection('events').withConverter(converter)
