








































import { Component, Vue, Prop, Ref } from 'vue-property-decorator'
import { VehicleSecond } from 'functions/src/types/vehicle/second'
import { AccidentPoints } from '@/../functions/src/types/vehicle/drive-score-points'
import PolylineMarker from '@/components/common/PolylineMarker.vue'
import LatLng from '@/types/LatLng'
declare const google: any
// import { google } from 'google.maps'

@Component<SecondsTable>({
  components: { PolylineMarker },
  mounted () {
    this.$nextTick(() => {
      this.fitBounds()
    })
  }
})
export default class SecondsTable extends Vue {
  @Ref('map') map!: { $mapPromise: any }
  @Prop({ type: Array, required: true, default: () => [] }) seconds!: VehicleSecond[]
  @Prop({ type: Array, required: true, default: () => [] }) accidentMarkPoints!: AccidentPoints[]

  get filteredSeconds (): VehicleSecond[] {
    return this.seconds.filter(item => item.latitude && item.longitude)
  }

  get items (): LatLng[] {
    return this.filteredSeconds.map(item => {
      return { lat: item.latitude, lng: item.longitude }
    })
  }

  get firstItem (): LatLng {
    return this.items[0]
  }

  get firstSecond (): VehicleSecond {
    return this.filteredSeconds[0]
  }

  get lastSecond (): VehicleSecond {
    return this.filteredSeconds[this.filteredSeconds.length - 1]
  }

  async fitBounds (): Promise<void> {
    if (!this.items.length) return
    const map = await this.map.$mapPromise

    const bounds = new google.maps.LatLngBounds()
    this.items.forEach(v => {
      bounds.extend(v)
    })
    map.fitBounds(bounds)
  }
}
