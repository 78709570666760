















































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import firebase, { storage } from '@/plugins/firebase'
import { Trip } from '@/models/trip'
import SecondsTab from '@/components/common/SecondsTab.vue'
import partCollection from '@/models/part'
import { VehicleSecond } from 'functions/src/types/vehicle/second'

@Component<SecondsDialog>({
  components: { SecondsTab }
})
export default class SecondsDialog extends Vue {
  @Prop({ type: Object, required: true }) doc!: firebase.firestore.QueryDocumentSnapshot<Trip>
  @Prop({ type: Boolean, default: () => false }) plain!: boolean

  dialog = false
  loading = false
  seconds: VehicleSecond[] = []

  get item (): Trip {
    return this.doc.data()
  }

  get xs (): boolean {
    return this.$vuetify.breakpoint.xs
  }

  @Watch('dialog')
  onChangeDialog (opened: boolean): void {
    if (opened) this.fetch()
  }

  async fetch (): Promise<void> {
    try {
      this.loading = true
      const ref = storage.ref('trips').child(this.item.device.id).child(`${this.doc.id}.json`)
      const url = await ref.getDownloadURL()
      const response = await fetch(url)
      const seconds = await response.json()
      // this.scores = [] push
      this.seconds = seconds
    } catch (e) {
      if (e.code !== 'storage/object-not-found') return
      const sn = await partCollection
        .where('device', '==', this.item.device)
        .where('company', '==', this.item.company)
        .where('group', '==', this.item.group)
        .get()
      if (sn.empty) return
      const seconds: VehicleSecond[] = []
      sn.docs.forEach(doc => seconds.push(...doc.data().seconds))
      seconds.sort(function (a, b) {
        if (a.time > b.time) return 1
        if (a.time < b.time) return -1
        return 0
      })
      this.seconds = seconds
    } finally {
      this.loading = false
    }
  }
}
