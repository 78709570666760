

















import { Component, Vue, Prop } from 'vue-property-decorator'
import { VehicleSecond } from 'functions/src/types/vehicle/second'
import moment from '@/plugins/moment'

@Component({ components: { } })
export default class SecondsTable extends Vue {
  @Prop({ type: Array, required: true, default: [] }) seconds!: VehicleSecond[]

  headers = [
    { value: 'time', text: '시간' },
    { value: 'speed', text: '속도' },
    { value: 'rpm', text: 'RPM' },
    { value: 'azimuth', text: '방위각' },
    { value: 'signal', text: '신호상태' },
    { value: 'latitude', text: '위도' },
    { value: 'longitude', text: '경도' },
    { value: 'trip', text: '운행거리' },
    { value: 'fuel', text: '유류사용량' }
  ]

  time (date: string): string {
    return moment(date).format('HH:mm:ss')
  }

  decimalFix (decimal: number): string {
    return decimal.toFixed(6)
  }
}
