


















































import { Component, Vue, Prop } from 'vue-property-decorator'
import { VehicleSecond } from 'functions/src/types/vehicle/second'
import GmapCustomMarker from 'vue2-gmap-custom-marker'
import moment from '@/plugins/moment'

interface LatLng {
  lat: number;
  lng: number;
}
@Component({ components: { GmapCustomMarker } })
export default class PolylineMarker extends Vue {
  @Prop({ type: Object, required: true }) item!: VehicleSecond
  @Prop({ type: String, required: true, default: 'start' }) type!: string

  get time (): string {
    return moment(this.item.time).format('a h:mm:ss')
  }

  get marker (): LatLng {
    return { lat: this.item.latitude, lng: this.item.longitude }
  }

  get icon (): string {
    let iconName = 'mdi-check'
    switch (this.type) {
      case 'start':
        iconName = 'mdi-play'
        break
      case 'end':
        iconName = 'mdi-stop'
        break
      case 'overSpeed':
        iconName = 'mdi-upload'
        break
      case 'longOverSpeed':
        iconName = 'mdi-upload-multiple'
        break
      case 'fastAcceleration':
        iconName = 'mdi-speedometer'
        break
      case 'fastDeceleration':
        iconName = 'mdi-speedometer-slow'
        break
      case 'fastRouteChange':
        iconName = 'mdi-swap-horizontal'
        break
      case 'fastTurn':
        iconName = 'mdi-axis-z-rotate-counterclockwise'
        break
      default:
        break
    }
    return iconName
    // return this.type === 'start' ? 'mdi-play' : 'mdi-stop'
  }

  get color (): string {
    let iconColor = 'default'
    switch (this.type) {
      case 'start':
        iconColor = 'success'
        break
      case 'end':
        iconColor = 'error'
        break
      case 'overSpeed':
        iconColor = 'red'
        break
      case 'longOverSpeed':
        iconColor = 'pink'
        break
      case 'fastAcceleration':
        iconColor = 'orange'
        break
      case 'fastDeceleration':
        iconColor = 'cyan'
        break
      case 'fastRouteChange':
        iconColor = 'lime'
        break
      case 'fastTurn':
        iconColor = 'blue-grey'
        break
      default:
        break
    }
    return iconColor
    // return this.type === 'start' ? 'success' : 'error'
  }

  get showAzimuth (): boolean {
    let show = false
    switch (this.type) {
      case 'overSpeed':
      case 'longOverSpeed':
      case 'fastAcceleration':
      case 'fastDeceleration':
        break
      case 'fastRouteChange':
      case 'fastTurn':
        show = true
        break
      default:
        break
    }
    return show
  }

  get showSpeedLimit (): boolean {
    let show = false
    switch (this.type) {
      case 'overSpeed':
      case 'longOverSpeed':
        show = true
        break
      case 'fastAcceleration':
      case 'fastDeceleration':
      case 'fastRouteChange':
      case 'fastTurn':
        break
      default:
        break
    }
    return show
  }
}
